import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { CompanyProductPlan } from '@nicejob-library/data-platform-implementation/entities/Company/ProductStatus/types';
import { CompanyProductId } from '@nicejob-library/data-platform-implementation/entities/Company/types';
import { ILanguage } from '@nicejob-library/internationalization';
import { IValidationError } from '@nicejob-library/react-form-validation';
import { AxiosError } from 'axios';
import { KeyboardEvent, MouseEvent } from 'react';
import { IProduct } from '../../../common/product-slugs';
import { VALIDATION_FIELD } from '../../../shared/types';

export enum ScreenStep {
    PHONE_NUMBER_INPUT = 0,
    SIGNUP_INPUT = 1,
}

export interface IStepState {
    step: ScreenStep;
}

export type TProduct = { product: CompanyProductId; plan: CompanyProductPlan };

export type TProducts = Array<TProduct>;

export type TUtm = {
    referrer?: string;
    landing_page?: string;
    os?: string;
    browser?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_source?: string;
    utm_network?: string;
};

export interface IPostRegisterUserData {
    info: {
        user_name: string;
        email: string;
        phone: string;
        password: string;
        skip_password_match: 1;
        coupon?: string;
        address?: IAddress;
    };
    products: TProducts;
    utm: TUtm | undefined;
    language: ILanguage;
    should_resolve_synchronously: boolean;
}

export interface IRegisterUser {
    user_name: string;
    email: string;
    phone: string;
    country_iso_code?: string;
    verification_id?: string;
    password: string;
}

export const PREPOPULATED_VALUE_KEYS = [
    'user_name',
    'email',
    'phone',
    'company_name',
    'address',
    'unit',
    'city',
    'country',
    'state',
    'zip',
    'coupon',
] as const;

type PrepopulatedValueKey = typeof PREPOPULATED_VALUE_KEYS[number];

export interface IRegisterPageQueryParams {
    products?: string;
    coupon?: string;
    parsed_products: Array<IProduct>;
    has_utm: boolean;
    utm?: Record<string, string | null>;

    // partial because the URL may contain some, all or none of the keys
    prepopulated_values?: Partial<Record<PrepopulatedValueKey, string>>;
}

export interface IRegisterBaseProps {
    // context
    language: ILanguage;
    query_params: IRegisterPageQueryParams;

    // state
    alert_message: string;
    duplicate_email_validation_errors: Partial<IValidationError>;
    setDuplicateEmailValidationErrors: (errors: Partial<IValidationError>) => void;
    is_loading: boolean;
    setIsLoading: (is_loading: boolean) => void;
    is_valid: boolean;
    setIsValid: (is_valid: boolean) => void;
    validation_errors: Partial<IValidationError>;
    setValidationErrors: (errors: Partial<IValidationError>) => void;
    clearValidationError: (field: VALIDATION_FIELD) => void;

    // handlers
    onSubmit: (
        e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
        submit: () => Promise<void>
    ) => Promise<void>;
    onSubmitError: (e: AxiosError) => void;
    onValidation: (errors: Partial<IValidationError>) => void;
}
