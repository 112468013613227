// The name of a Network is used to group networks in the front-end Integrations list page.
// See https://github.com/nicejobinc/microservices/tree/master/appengine-routes/frontend-route/src/pages/Settings/Product/Integrations/ChildComponents/common/utils.ts#L68
export enum NetworkName {
    ACCULYNX = 'acculynx',
    BBB = 'bbb',
    CAPTERRA = 'capterra',
    CITYSEARCH = 'citysearch',
    CLIO = 'clio',
    COMPANYCAM = 'companycam',
    CONVERT_LABS = 'convert_labs',
    DATACANDY = 'datacandy',
    DRCHRONO = 'drchrono',
    FACEBOOK = 'facebook',
    FIELDPULSE = 'fieldpulse',
    FRESHBOOKS = 'freshbooks',
    G2 = 'g2',
    GOOGLE = 'google',
    GOOGLE_RSV = 'google-rsv',
    HOMEADVISOR = 'homeadvisor',
    HOMESTARS = 'homestars',
    HOUSECALLPRO = 'housecallpro',
    HUBSPOT = 'hubspot',
    INSTAGRAM = 'instagram',
    INTERCOM = 'intercom',
    JOBBER = 'jobber',
    JOB_TREAD = 'job_tread',
    LIBRO = 'libro',
    LINKEDIN = 'linkedin',
    MARKATE = 'markate',
    MINDBODY = 'mindbody',
    NICEJOB = 'nicejob',
    PAYPAL = 'paypal',
    QDS = 'qds',
    QUICKBOOKS = 'quickbooks',
    RESPONSIBID = 'responsibid',
    SENDJIM = 'sendjim',
    SERVICEMONSTER = 'servicemonster',
    SERVICETITAN = 'servicetitan',
    SQUARE = 'square',
    STRIPE = 'stripe',
    TRIPADVISOR = 'tripadvisor',
    TWITTER = 'twitter',
    VONIGO = 'vonigo',
    XERO = 'xero',
    YELP = 'yelp',
    YP = 'yp',
    ZENDESK = 'zendesk',
    ZILLOW = 'zillow',
}
