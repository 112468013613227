import {
    useDocumentKeydownListener,
    useIsMount,
    useLatestRef,
    usePrevious,
} from '@nicejob-library/react-hooks';
import React, {
    Children,
    cloneElement,
    CSSProperties,
    Dispatch,
    forwardRef,
    isValidElement,
    MutableRefObject,
    ReactElement,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Dropdown, DropdownElement } from '../dropdown/Dropdown';
import { Menu } from '../menu/Menu';
import { MenuWrapper } from './Select.styles';
import { SelectFooter } from './SelectFooter';
import { SelectOption } from './SelectOption';
import SelectTrigger from './SelectTrigger';

const ARROW_UP_KEYCODE = 38;
const ARROW_DOWN_KEYCODE = 40;
const ENTER_KEYCODE = 13;
const ESCAPE_KEYCODE = 27;

export type ValueType = string | number | boolean;

export type SelectElement = {
    open: () => void;
    close: () => void;
};

export interface IValueProps<T extends ValueType> {
    value: T;
    label: string;
    display?: string;
    active?: boolean;
}

export interface ISelectProps {
    children: ReactNode;
    default_value: IValueProps<ValueType> | null;
    allow_clear?: boolean;
    has_error?: boolean;
    is_operator?: boolean;
    disabled?: boolean;
    loading?: boolean;
    blocked?: boolean;
    stretch?: boolean;
    rounded?: 'semi' | 'none' | 'round';
    max_menu_height?: number;
    menu_width?: string;
    icon?: ReactElement;
    custom_trigger?:
        | ReactElement
        | ((args: {
              menu_open: boolean;
              setMenuOpen: Dispatch<SetStateAction<boolean>>;
              trigger_ref?: MutableRefObject<any>;
              label?: string;
          }) => ReactElement);
    default_open?: boolean;
    additional_styles?: string;
    additional_dropdown_styles?: Partial<CSSProperties>;
    footer_content?: ReactElement;
    trigger_ref?: MutableRefObject<any>;
    formatLabel?: (label: string) => string;
    onChange?: (selected: IValueProps<ValueType> | null) => void;
    onClear?: () => void;
    onOutsideClick?: () => void;
    onDropdownOpen?: () => void;
}

export interface IStyledSelectTriggerProps {
    has_error?: boolean;
    disabled?: boolean;
    allow_clear?: boolean;
    is_operator?: boolean;
    stretch?: boolean;
    is_loading?: boolean;
    rounded?: 'semi' | 'none' | 'round';
    icon?: ReactElement;
    additional_styles?: string;
}

export interface ISelectFooterProps {
    children?: ReactNode;
    className?: 'string';
}

export interface ISelectLabelProps {
    is_operator?: boolean;
}

export interface ICaretWrapperProps {
    menu_open: boolean;
}

export const Select = forwardRef<DropdownElement, ISelectProps>(function(
    {
        children,
        default_value,
        onChange,
        onClear,
        has_error,
        allow_clear,
        is_operator,
        disabled,
        loading,
        blocked,
        stretch,
        rounded,
        max_menu_height,
        menu_width,
        icon,
        footer_content,
        custom_trigger,
        formatLabel,
        default_open = false,
        additional_styles,
        additional_dropdown_styles,
        onOutsideClick,
        trigger_ref,
        onDropdownOpen,
    },
    forwarded_ref
): ReactElement {
    if (forwarded_ref instanceof Function) {
        throw new Error(`Select forwarded_ref must be DropdownElement, and not function`);
    }

    const [selected, setSelected] = useState<IValueProps<ValueType> | null>({
        value: default_value ? default_value.value : '',
        label: default_value ? default_value.label : '',
    });

    // only to be controlled by the dropdown onOpen and onClose.
    const [is_menu_open, setIsMenuOpen] = useState(false);
    const [keyboard_selected_index, setKeyboardSelectedIndex] = useState<number | null>(null);

    const menu_length = React.Children.count(children);

    // if we are passed a ref from forwardRef we will use it otherwise we will initialize our own as we need it internally
    const inner_ref = useRef<DropdownElement>(null);
    const ref = forwarded_ref || inner_ref;

    const children_ref = useLatestRef(React.Children.toArray(children));

    /**  @const {Boolean} is_mount – `true` on initial render; `false` thereafter  */
    const is_mount = useIsMount();

    // Handle selection
    function selectItem({ value, label, display }: IValueProps<ValueType>): void {
        setSelected({ value, label, display });
        ref?.current?.close();
    }

    // Handle default open
    useEffect(() => {
        if (default_open) {
            ref?.current?.open();
        } else {
            ref?.current?.close();
        }
    }, [default_open]);

    // Handle Default value change
    useDeepCompareEffectNoCheck(() => {
        default_value && setSelected(default_value);
        if (default_value) {
            if (default_open) {
                ref?.current?.open();
            } else {
                ref?.current?.close();
            }
        }
    }, [default_value]);

    const handleMenuKeydown = useCallback(
        (event: KeyboardEvent): void => {
            switch (event.keyCode) {
                case ARROW_DOWN_KEYCODE:
                    event.preventDefault();

                    setKeyboardSelectedIndex(prev => {
                        if (prev === null) {
                            return 0;
                        } else {
                            return prev < menu_length - 1 ? prev + 1 : prev;
                        }
                    });
                    break;
                case ARROW_UP_KEYCODE:
                    event.preventDefault();

                    setKeyboardSelectedIndex(prev =>
                        prev === null ? null : prev > 0 ? prev - 1 : null
                    );
                    break;
                case ENTER_KEYCODE:
                    event.preventDefault();

                    if (keyboard_selected_index !== null) {
                        selectItem(
                            (children_ref.current[keyboard_selected_index] as ReactElement)?.props
                        );
                    }

                    ref?.current?.close();
                    break;
                case ESCAPE_KEYCODE:
                    event.preventDefault();

                    ref?.current?.close();
                    break;
                default:
                    break;
            }
        },
        [menu_length, keyboard_selected_index]
    );

    // Handle event listeners for keyboard shortcuts
    useDocumentKeydownListener(is_menu_open, handleMenuKeydown);

    const previous_selected = usePrevious(selected);

    useEffect(() => {
        if (is_mount || !onChange) {
            return;
        }

        if (selected?.value !== previous_selected?.value) {
            onChange(selected);
        }
    }, [selected]);

    // Add 'selectItem' and 'active' to each child.
    function RenderChildren(): ReactElement {
        const childrenWithProps = Children.map(children, (child, index) => {
            if (isValidElement(child)) {
                const active = index === keyboard_selected_index;

                return cloneElement(child, {
                    selectItem,
                    active,
                });
            }

            return child;
        });

        return <>{childrenWithProps}</>;
    }

    function onOpen(): void {
        setIsMenuOpen(true);
        if (onDropdownOpen && typeof onDropdownOpen === 'function') {
            onDropdownOpen();
        }
    }

    function onClose(): void {
        setIsMenuOpen(false);
        setKeyboardSelectedIndex(null);
    }

    function onMouseOver(): void {
        setKeyboardSelectedIndex && setKeyboardSelectedIndex(null);
    }

    return (
        <Dropdown
            ref={ref}
            onOutsideClick={onOutsideClick}
            onOpen={onOpen}
            onClose={onClose}
            blocked={blocked}
            additional_styles={additional_dropdown_styles}
            renderTrigger={(menu_open, setMenuOpen): ReactElement => {
                return (
                    <>
                        {custom_trigger ? (
                            typeof custom_trigger === 'function' ? (
                                custom_trigger({
                                    menu_open,
                                    setMenuOpen,
                                    trigger_ref,
                                    label: selected?.label,
                                })
                            ) : (
                                <span
                                    ref={trigger_ref}
                                    onClick={(): void => {
                                        setMenuOpen(!menu_open);
                                    }}
                                >
                                    {custom_trigger}
                                </span>
                            )
                        ) : (
                            <SelectTrigger
                                ref={trigger_ref}
                                label={
                                    selected
                                        ? formatLabel
                                            ? formatLabel(selected.display || selected.label)
                                            : selected.display || selected.label
                                        : null
                                }
                                onClick={(): void => {
                                    setMenuOpen(!menu_open);
                                }}
                                menu_open={menu_open}
                                has_error={has_error}
                                is_operator={is_operator}
                                disabled={disabled}
                                loading={loading}
                                stretch={stretch}
                                rounded={rounded || 'round'}
                                icon={icon}
                                additional_styles={additional_styles}
                                blocked={blocked}
                                allow_clear={allow_clear}
                                onClear={onClear}
                            />
                        )}
                    </>
                );
            }}
        >
            <MenuWrapper onMouseOver={onMouseOver}>
                <Menu max_height={max_menu_height} width={menu_width}>
                    {RenderChildren()}
                </Menu>
                {footer_content ? <SelectFooter>{footer_content}</SelectFooter> : null}
            </MenuWrapper>
        </Dropdown>
    );
});

export { SelectOption, SelectFooter };
