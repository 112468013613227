import { WidgetName } from '.';
import { IWidgetDataset, ISDKEventMetadata } from '.';
import { IClientIdentify } from '../session.types';
import { EntityMetadata, SDKBaseElementProperties } from '../entities.types';

//  ====

/**
 * SDK events: Client emission
 */
export enum ISDKEvent {
    view = 'view',
    click = 'click',
    webform = 'webform',
    pageview = 'pageview',
}

export interface ISDKSharedPayloadProperties<
    M extends EntityMetadata = EntityMetadata,
    D = undefined
> extends Partial<SDKBaseElementProperties<M>> {
    element?: HTMLElement;
    data?: D;
}

export type ISDKEventPayload = ISDKSharedPayloadProperties<ISDKEventMetadata>;

/**
 * Event send payload
 */
export interface ISDKSendEventPayload<
    Payload extends SDKBaseElementProperties = SDKBaseElementProperties
> {
    k: string; // public key
    c: string; // company_id
    r: boolean; // reviewly
    s: string; // session_id
    pv: string; // pageview_id
    i: IClientIdentify;
    e: ISDKEvent;
    pl: Payload;
    csrf: string; // CSRF token
}

export interface ISDKParsedEventPayload<
    Payload extends SDKBaseElementProperties = SDKBaseElementProperties
> {
    c: string; // company_id
    r: boolean; // reviewly
    s: string; // session_id
    pv: string; // pageview_id
    i: IClientIdentify;
    e: ISDKEvent;
    pl: Payload;
    csrf: string; // CSRF token
}

export interface ISDKSendEventResponse {
    success: boolean;
    code: number;
    error?: string;
}

/**
 * DEPRECATE
 * - WidgetEvent
 */
export type IWidgetEvent = 'Pulse_Expand' | 'Pulse_MoreStories' | 'Pulse_Close' | 'Pulse_Open';
export interface IEmitWidgetEventOptions<T> {
    el: HTMLElement;
    event: IWidgetEvent;
    payload: T;
}
export interface IWidgetEventEmissionBody {
    source: {
        widget: WidgetName;
        dataset: IWidgetDataset;
        dt_load: number;
        dt_event: number;
    };
    event: IWidgetEvent;
    payload: any;
    session_id: string;
    company_id: string;
    reviewly: boolean;
}
